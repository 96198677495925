import Cookies from "universal-cookie";

export interface CookieResponse {
    name: string;
    value: string | null;
}

const cookies = new Cookies();

export const createCookie = (
    cookieName: string,
    value: string,
    valueTimeInSeconds: number
): void => {
    cookies.set(`${cookieName}`, `${value}`, {
        sameSite: "strict",
        path: "/",
        expires: new Date(new Date().getTime() + valueTimeInSeconds * 1000),
    });
};

export const cookieExists = (name: string): boolean => {
    const userCookie = cookies.get(name);
    if (userCookie) return true;
    return false;
};

export const getCookie = (nameOftheString: string): CookieResponse => {
    const userCookie = cookies.get(nameOftheString);
    const cookieResponse: CookieResponse = {
        name: nameOftheString,
        value: userCookie,
    };
    return cookieResponse;
};

export const deleteCookie = (nameOftheString: string): void => {
    cookies.remove(nameOftheString);
};
