import {
    updateUserAttribute,
    // type UpdateUserAttributeOutput,
} from "aws-amplify/auth";

import { errorMessageHandler } from "./errorMessageHandler";

export const handleUpdateUserAttribute = async (
    attributeKey: string,
    value: string
): Promise<string> => {
    try {
        await updateUserAttribute({
            userAttribute: {
                attributeKey,
                value,
            },
        });

        return "true";
        //if something else needs to be done after the attribute update
        // handleUpdateUserAttributeNextSteps(output);
    } catch (error) {
        const errorMessage: string = errorMessageHandler(error);
        if (
            errorMessage === "User needs to be authenticated to call this API."
        ) {
            return "Sinun täytyy kirjautua sisään, jotta voit päivittää puhelinnumerosi.";
        } else {
            return errorMessage;
        }
    }
};

// function handleUpdateUserAttributeNextSteps(
//     output: UpdateUserAttributeOutput
// ) {
//     switch (nextStep.updateAttributeStep) {
//         // case "CONFIRM_ATTRIBUTE_WITH_CODE":
//         //     console.log(
//         //         `Confirmation code was sent to ${codeDeliveryDetails?.deliveryMedium}.`
//         //     );
//         //     // Collect the confirmation code from the user and pass to confirmUserAttribute.
//         //     break;
//         case "DONE":
//             break;
//         default:
//             break;
//     }
// }
