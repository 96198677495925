export const genderAttributeDisplayEngToFin = (gender: string): string => {
    switch (gender) {
        case "male":
            return "Mies";
        case "female":
            return "Nainen";
        case "other":
            return "Muu";
        case "":
            return "";
        default:
            return "";
    }
};

export const genderAttributeDisplayFinToEng = (gender: string): string => {
    switch (gender) {
        case "Mies":
            return "male";
        case "Nainen":
            return "female";
        case "Muu":
            return "other";
        case "":
            return "";
        default:
            return "notFound";
    }
};
