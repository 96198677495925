import { useState, useEffect, ReactNode } from "react";

import { Link } from "react-router-dom";

import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormGroup,
    SelectChangeEvent,
} from "@mui/material";

import "dayjs/locale/fi";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { useUserLoggedIn } from "../contexts/UserLoggedInContex";


import {
    validatePhoneNumber,
    nameValidator,
    genderValidator,
} from "../utils/userAttributeValidator";
import { handleUpdateUserAttribute } from "../utils/userAttributesUpdater";
import {
    genderAttributeDisplayEngToFin,
    genderAttributeDisplayFinToEng,
} from "../utils/genderAttributeTranslator";

import SmallLogo from "./SmallLogo";
import {
    PickerChangeHandlerContext,
    DateValidationError,
} from "@mui/x-date-pickers";

interface PageState {
    givenName: String;
    familyName: String;
    phoneNumber: String;
    gender: String;
    birthdate: String | null;
}

interface PageError {
    errMsg: String;
    errMsgPhone: String;
    errMsgGivenName: String;
    errMsgFamilyName: String;
    errMsgGender: String;
    errMsgBirthdate: String;
}

const initialPageError: PageError = {
    errMsg: "",
    errMsgPhone: "",
    errMsgGivenName: "",
    errMsgFamilyName: "",
    errMsgGender: "",
    errMsgBirthdate: "",
};

const EditUserAttributes2 = () => {
    const { userLoggedInState, userLoggedInDispatch } = useUserLoggedIn();


    // const [color, setColor] = useState<String>("white");

    const [inputs, setInputs] = useState<PageState>({
        givenName: userLoggedInState.userAttributes?.given_name as string,
        familyName: userLoggedInState.userAttributes?.family_name as string,
        phoneNumber: userLoggedInState.userAttributes?.phone_number as string,
        gender: genderAttributeDisplayEngToFin(
            userLoggedInState.userAttributes?.gender as string
        ),
        birthdate: userLoggedInState.userAttributes?.birthdate as string,
    });

    const [error, setError] = useState<PageError>(initialPageError);
    const [updateSucces, setupdateSucces] = useState<string>("");

    // const [disableInputFields, setDisableInputFields] = useState<
    //     boolean | undefined
    // >(true);
    // let color = "white"

    //Functio that makes the necessary function calls to update the user attributes to DB and to context api
    const updateUserAttributes = async () => {
        try {

            const updatedPhoneNumber: Promise<string> = //eslint-disable-line
                handleUpdateUserAttribute(
                    "phone_number",
                    inputs.phoneNumber as string
                );
            const updatedGivenName: Promise<string> = handleUpdateUserAttribute( //eslint-disable-line
                "given_name",
                inputs.givenName as string
            );
            const updatedFamilynName: Promise<string> = //eslint-disable-line
                handleUpdateUserAttribute( 
                    "family_name",
                    inputs.familyName as string
                );

            const translatedGender: string = genderAttributeDisplayFinToEng(
                inputs.gender as string
            );
            const updatedGender: Promise<string> = handleUpdateUserAttribute( //eslint-disable-line
                "gender",
                translatedGender
            );

            const updatedBirthdate: Promise<string> = handleUpdateUserAttribute( //eslint-disable-line
                "birthdate",
                inputs.birthdate as string
            );

            userLoggedInDispatch({
                type: "setLoggedInUserAttributes",
                payload: {
                    ...userLoggedInState.userAttributes,
                    phone_number: inputs.phoneNumber as string,
                    given_name: inputs.givenName as string,
                    family_name: inputs.familyName as string,
                    gender: translatedGender,
                    birthdate: inputs.birthdate as string,
                },
            });
        } catch (error) {

            handleErrorMessage("errMsg", error as string);
        }
    };

    /**
     * HANDLER FUNCTIONS
     */
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setInputs((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };
    const handleGenderChange = (
        e: SelectChangeEvent<String>,
        child: ReactNode
    ): void => {
        setInputs((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    const handleBdayChange = (
        e: React.ChangeEvent<HTMLInputElement> | null,
        context: PickerChangeHandlerContext<DateValidationError>
    ): void => {
        let stringDate = JSON.stringify(e);
        const date = new Date(stringDate.replace(/"/g, ""));
        if (isNaN(date.getTime())) {
            handleErrorMessage("errMsgBirthdate", "Korjaa syntymäpäivä");
        } else {
            const formattedDate = date.toLocaleDateString("fi-FI", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
            });
            setInputs((prevState) => ({
                ...prevState,
                birthdate: formattedDate,
            }));
        }
    };

    const handleErrorMessage = (targetError: string, message: string): void => {
        setError((prevState) => ({
            ...prevState,
            [targetError]: message,
        }));
    };

    /**
     * Before submitting the form we'll make validation for the attribute values
     */
    const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let count: number = 0;
        const number: string = inputs.phoneNumber.split(" ").join("");
        if (validatePhoneNumber(number)) {
            setInputs((prevState) => ({
                ...prevState,
                phoneNumber: number,
            }));
            count += 1;
        } else {
            handleErrorMessage(
                "errMsgPhone",
                "Puhelin numeron tulee olla muodossa +358 xx xxxxxxx"
            );
        }
        if (nameValidator(inputs.givenName as string)) {
            count += 1;
        } else {
            handleErrorMessage(
                "errMsgGivenName",
                "Etunimen täytyy olla vähintään kaksi kirjainta pitkä, eikä se saa sisältää muita kuin kirjaimia"
            );
        }
        if (nameValidator(inputs.familyName as string)) {
            count += 1;
        } else {
            handleErrorMessage(
                "errMsgFamilyName",
                "Sukunimen täytyy olla vähintään kaksi kirjainta pitkä, eikä se saa sisältää muita kuin kirjaimia"
            );
        }
        if (genderValidator(inputs.gender as string)) {
            count += 1;
        } else {
            handleErrorMessage(
                "errMsgGender",
                "Valitse listasta jokin sukupuoli."
            );
        }

        if (count === 4) {
            updateUserAttributes();
            setupdateSucces("Tiedot tallennettu onnistuneesti.");
        }
    };

    //this is for color if some user atrributes are dissabled
    // not in use right now
    // --> color: `${color} !important`,
    // useEffect(() => {
    //     if (userLoggedInState.userAttributes?.identities) {
    //         setDisableInputFields(true);
    //         setColor("rgba(228, 219, 233, 0.25)");
    //     } else {
    //         setDisableInputFields(false);
    //         setColor("white");
    //     }
    // }, [userLoggedInState.userAttributes?.identities]);

    /**
     * use Effects for handling error message erase
     */
    useEffect(() => {
        handleErrorMessage("errMsg", "");
        setupdateSucces("");
    }, [
        inputs.phoneNumber,
        inputs.givenName,
        inputs.familyName,
        inputs.birthdate,
    ]);
    useEffect(() => {
        handleErrorMessage("errMsgPhone", "");
    }, [inputs.phoneNumber]);
    useEffect(() => {
        handleErrorMessage("errMsgGivenName", "");
    }, [inputs.givenName]);
    useEffect(() => {
        handleErrorMessage("errMsgFamilyName", "");
    }, [inputs.familyName]);
    useEffect(() => {
        handleErrorMessage("errMsgBirthdate", "");
    }, [inputs.birthdate]);

    return (
        <div className="row edit-user-attributes">
            <div className="row text-center mt-2">
                <p>OMAT TIEDOT</p>
            </div>
            <div className="row text-center mt-3">
                <SmallLogo />
            </div>
            <div className="row mt-3 text-center">
                <h2>MUOKKAA TIETOJA </h2>
            </div>
            <div
                // ref={errRef}
                className={
                    error.errMsg ||
                    error.errMsgPhone ||
                    error.errMsgGivenName ||
                    error.errMsgFamilyName ||
                    error.errMsgBirthdate ||
                    updateSucces
                        ? "errmsg user-attributes-update-error text-center"
                        : "offscreen m-0 p-0 b-0"
                }
                aria-live="assertive"
            >
                <p>{error.errMsg}</p>
                <p>{error.errMsgFamilyName}</p>
                <p>{error.errMsgGivenName}</p>
                <p>{error.errMsgPhone}</p>
                <p>{error.errMsgBirthdate}</p>
                <p>{updateSucces}</p>
            </div>
            <form className="user-attribute-form row" onSubmit={submitForm}>
                <FormGroup>
                    <TextField
                        className="pb-2"
                        label={"Etunimi"}
                        name="givenName"
                        value={inputs.givenName}
                        onChange={handleChange}
                        type={"text"}
                        placeholder={"Etunimi"}
                        // disabled={disableInputFields}
                        sx={{
                            ".MuiInputLabel-root": {
                                color: `white !important`,
                                // color: `${color} !important`,
                            },
                        }}
                    />
                    <TextField
                        className="pb-2"
                        label={"Sukunimi"}
                        name="familyName"
                        value={inputs.familyName}
                        onChange={handleChange}
                        type={"text"}
                        placeholder={"Sukunimi"}
                        // disabled={disableInputFields}
                        sx={{
                            ".MuiInputLabel-root": {
                                color: `white !important`,
                                // color: `${color} !important`,
                            },
                        }}
                    />
                    <TextField
                        className="pb-2"
                        label={"Puhelinnumero"}
                        name="phoneNumber"
                        value={inputs.phoneNumber}
                        onChange={handleChange}
                        type={"text"}
                        placeholder={"Puhelinnumero"}
                        sx={{
                            ".MuiInputLabel-root": {
                                color: `white !important`,
                            },
                        }}
                    />
                    <FormControl
                        className="pb-2"
                        // disabled={disableInputFields}
                    >
                        <InputLabel
                            // disabled={disableInputFields}
                            className="Mui-Menu-Item"
                            sx={{
                                ".floatingLabelFocusStyle": {
                                    // color: `${color} !important`,
                                    color: `white !important`,
                                },
                            }}
                        >
                            Sukupuoli
                        </InputLabel>
                        <Select
                            // labelStyle={{ color: "#ff0000" }}
                            // disabled={disableInputFields}
                            sx={{
                                color: `white !important`,
                                // color: `${color} !important`,
                                ".MuiSelect-select": {
                                    // color: `${color} !important`,
                                    color: `white !important`,
                                },

                                // ".MuiOutlinedInput-notchedOutline": {
                                //     borderColor: "rgba(228, 219, 233, 0.25)",
                                // },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                        // borderColor:
                                        //     "rgba(228, 219, 233, 0.25)",
                                    },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    // borderColor: "rgba(228, 219, 233, 0.25)",
                                },
                                ".MuiSvgIcon-root ": {
                                    fill: "white !important",
                                },
                            }}
                            className="gender-dropdown-selector"
                            value={inputs.gender}
                            label="gender"
                            name="gender"
                            onChange={handleGenderChange}
                        >
                            <MenuItem
                                className="Mui-Menu-Item"
                                value={"Mies"}
                                sx={{
                                    ".MuiOutlinedInput-notchedOutline": {
                                        borderColor:
                                            "rgba(228, 219, 233, 0.25)",
                                    },
                                    ".MuiInputLabel-root": {
                                        // color: `${color} !important`,
                                        color: `white !important`,
                                    },
                                }}
                            >
                                Mies
                            </MenuItem>
                            <MenuItem
                                className="Mui-Menu-Item"
                                value={"Nainen"}
                            >
                                Nainen
                            </MenuItem>
                            <MenuItem className="Mui-Menu-Item" value={"Muu"}>
                                Muu
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="fi"
                    >
                        <DatePicker
                            className="pb-3"
                            label={"Syntymäpäivä"}
                            name="birthdate"
                            disableFuture={true}
                            onChange={handleBdayChange}
                            sx={{
                                ".MuiInputLabel-root": {
                                    color: `white !important`,
                                },
                                ".css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root":
                                    {
                                        backgroundColor: `hsl(0,0%,17%) !important`,
                                    },
                            }}
                        />
                    </LocalizationProvider>
                    <div className="row">
                        <div className="text-center">
                            <button
                                type="submit"
                                className="btn btn-outline-secondary bt-same-size-user-attributes"
                            >
                                Tallenna
                            </button>
                        </div>
                    </div>
                </FormGroup>

                <div className="mt-2 text-center">
                    <button className="btn btn-outline-secondary bt-same-size-user-attributes">
                        <Link className="linkTxt pb-0" to="/kirjautunut">
                            <p className="linkTxt m-0 user-attributes-go-back-btn-text">
                                Takaisin
                            </p>
                        </Link>
                    </button>
                </div>
            </form>
        </div>
    );
};

export default EditUserAttributes2;
