import { useState } from "react";

import { useUserLoggedIn } from "../contexts/UserLoggedInContex";
import { deleteUser } from "aws-amplify/auth";

import { errorMessageHandler } from "../utils/errorMessageHandler";
import { createCookie } from "../utils/cookieHandler";

function DeleteUser() {
    const { userLoggedInState, userLoggedInDispatch } = useUserLoggedIn();

    const [errMsg, setErrMsg] = useState<string>("");

    async function handleDeleteUser() {
        if (
            window.confirm(
                `Haluatko varmasti poistaa käyttäjän ${userLoggedInState.userAttributes?.email} tiedot kokonaan?`
            )
        ) {
            try {
                createCookie(
                    "userDeleted",
                    userLoggedInState.userAttributes?.email as string,
                    300
                );
                await deleteUser();
                userLoggedInDispatch({
                    type: "setUserDeleted",
                    payload: true,
                });

                if (!userLoggedInState.userAttributes?.identities) {
                    userLoggedInDispatch({
                        type: "loggedIn",
                        payload: "false",
                    });
                }
            } catch (error) {
                userLoggedInDispatch({
                    type: "setUserDeleted",
                    payload: false,
                });
                userLoggedInDispatch({
                    type: "loggedIn",
                    payload: "true",
                });
                setErrMsg(errorMessageHandler(error));
            }
        }
    }
    return (
        <div>
            <div className={errMsg ? "errmsg text-center" : "offscreen"}>
                <p aria-live="assertive">{<>{errMsg}</>}</p>
            </div>
            <div className="row">
                <div className="text-center ">
                    <button
                        className="btn btn-danger bt-same-size"
                        onClick={handleDeleteUser}
                    >
                        Poista käyttäjä
                    </button>
                </div>
            </div>
        </div>
    );
}

export default DeleteUser;
