import {
    createContext,
    useContext,
    useReducer,
    ReactNode,
    Dispatch,
} from "react";

type Status = "true" | "false" | "error";

type UserDeleted = boolean;

type PhoneNumber = string | null;

type BirthDate = string | null;

type Gender = string | null;

export interface LoggedInUserAttributes {
    sub?: string | null;
    email_verified?: string | null;
    phone_number_verified?: string | null;
    phone_number?: PhoneNumber;
    given_name?: string | null;
    family_name?: string | null;
    email?: string | null;
    gender?: Gender | null;
    identities?: string | null;
    birthdate?: BirthDate;
    "custom:market_perm"?: string | null;
}

interface UserLoggedInContext {
    userLoggedInState: UserLoggedInState;
    userLoggedInDispatch: Dispatch<UserLoggedInAction>;
}

interface UserLoggedInState {
    LoggedInStatus: Status;
    userAttributes: LoggedInUserAttributes | null;
    userDeleted: UserDeleted;
}

const initialState: UserLoggedInState = {
    LoggedInStatus: "false",
    userAttributes: null,
    userDeleted: false,
};

type UserLoggedInAction =
    | {
          type: "loggedIn";
          payload: Status;
          //   payload: UserLoggedInState["status"];
      }
    | {
          type: "setLoggedInUserAttributes";
          payload: LoggedInUserAttributes;
      }
    | {
          type: "setUserDeleted";
          payload: UserDeleted;
      }
    | {
          type: "emptyUser";
          payload: null;
      };

export const UserLoggedInContext = createContext<UserLoggedInContext>({ //eslint-disable-line
    userLoggedInState: initialState,
    userLoggedInDispatch: () => null,
});

type UserLoggedInProviderProps = {
    children: ReactNode;
};

export function UserLoggedInProvider({ children }: UserLoggedInProviderProps) {
    const [userLoggedInState, userLoggedInDispatch] = useReducer(
        UserLoggedInReducer,
        initialState
    );
    return (
        <>
            <UserLoggedInContext.Provider
                value={{ userLoggedInState, userLoggedInDispatch }}
            >
                {children}
            </UserLoggedInContext.Provider>
        </>
    );
}

export const useUserLoggedIn = () => {
    return useContext(UserLoggedInContext);
};

function UserLoggedInReducer(
    state: UserLoggedInState,
    action: UserLoggedInAction
): UserLoggedInState {
    switch (action.type) {
        case "loggedIn":
            return { ...state, LoggedInStatus: action.payload };
        case "setLoggedInUserAttributes":
            return { ...state, userAttributes: action.payload };
        case "setUserDeleted":
            return { ...state, userDeleted: action.payload };
        case "emptyUser":
            return { ...state, userAttributes: null };
        default:
            throw new Error("Action type not supported");
    }
}
