const Footer = () => {
    return (
        <>
            <div className="footer row text-center fixed-bottom d-flex justify-content-center">
                <div className="d-flex justify-content-center">
                    <div className="col-3 ms-5">
                        <p>Copyright © Liiga</p>
                    </div>
                    <div className="col-3">
                        <p>
                            <a
                                href="https://liiga.fi/fi/liiga/kayttoehdot"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Käyttöehdot
                            </a>
                        </p>
                    </div>
                    <div className="col-3">
                        <p>
                            <a
                                href="https://liiga.fi/fi/liiga/tietosuojaseloste"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Tietosuoja
                            </a>
                        </p>
                    </div>
                    <div className="col-3 me-5">
                        <p>
                            <a
                                href="https://liiga.fi/fi/liiga/evastekaytannot"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Evästekäytännöt
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Footer;
