import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchUserAttributes } from "aws-amplify/auth";

import {
    useUserLoggedIn,
    LoggedInUserAttributes,
} from "../contexts/UserLoggedInContex";
import { createCookie } from "../utils/cookieHandler";
import { genderAttributeDisplayEngToFin } from "../utils/genderAttributeTranslator";

import UpdatePhoneNumber from "./UpdatePhoneNumber";
import DeleteUser from "./DeleteUser";
import SmallLogo from "./SmallLogo";

function LoggedIn() {
    const { userLoggedInState, userLoggedInDispatch } = useUserLoggedIn();

    const [gender, setGender] = useState<String>("alustattamatta");

    async function handleFetchUserAttributes() {
        try {
            const userAttributes: LoggedInUserAttributes =
                await fetchUserAttributes();

            if (userAttributes) {
                userLoggedInDispatch({ type: "loggedIn", payload: "true" });
                userLoggedInDispatch({
                    type: "setLoggedInUserAttributes",
                    payload: userAttributes,
                });
            }
        } catch (error) {
            userLoggedInDispatch({ type: "loggedIn", payload: "false" });
        }
    }

    useEffect(() => {
        handleFetchUserAttributes();
        const userEmail: string = userLoggedInState.userAttributes
            ?.email as string;
        createCookie("email", userEmail, 600);
        setGender(
            genderAttributeDisplayEngToFin(
                userLoggedInState.userAttributes?.gender as string
            )
        );
    }, []); //eslint-disable-line

    return (
        <div className="logged-in row">
            {userLoggedInState.userAttributes?.phone_number ? (
                <>
                    <div className="col"></div>
                    <div className="col"></div>
                    <div className="row mb-3 mt-3 text-center">
                        <h2>OMAT TIEDOT</h2>
                    </div>
                    <div className="row text-center mb-4">
                        <SmallLogo />
                    </div>
                    <div className="row mb-1">
                        <h3>Tiedot</h3>
                    </div>
                    <div className="row">
                        <div className="col">
                            <p>Etunimi</p>
                        </div>
                        <div className="col">
                            <p>
                                {userLoggedInState.userAttributes?.given_name}
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <p>Sukunimi</p>
                        </div>
                        <div className="col">
                            <p>
                                {userLoggedInState.userAttributes?.family_name}
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <p>Puhelinnumero</p>
                        </div>
                        <div className="col">
                            <p>
                                {userLoggedInState.userAttributes?.phone_number}
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <p>Sähköposti</p>
                        </div>
                        <div className="col">
                            <p>{userLoggedInState.userAttributes?.email}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <p>Syntymäpäivä</p>
                        </div>
                        <div className="col">
                            <p>{userLoggedInState.userAttributes?.birthdate}</p>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col">
                            <p>Sukupuoli</p>
                        </div>
                        <div className="col">
                            <p>{gender}</p>
                        </div>
                    </div>
                    <div className="text-center">
                        <button className="btn btn-light btn btn-outline-light bt-same-size">
                            <Link className="linkTxt pb-0" to="/muokkaa">
                                <p className="linkTxt m-0">Muokkaa tietoja</p>
                            </Link>
                        </button>
                    </div>
                    <DeleteUser />
                </>
            ) : (
                <UpdatePhoneNumber />
            )}
        </div>
    );
}

export default LoggedIn;
