import { Outlet } from "react-router-dom";
import Footer from "./Footer";

import Logout from "./Logout";
import Cookie from "./Cookie";

const Layout = () => {
    return (
        <main className="App">
            <div className="background-image">
                <div className="app container-fluid m-0 p-0 border-0">
                    <div className="row ms-1 me-1">
                        <div className="col-1 col-sm-2 col-md-3 col-lg-4"></div>
                        <div className="col-10 col-sm-8 col-md-6 col-lg-4">
                            <>
                                <Logout />
                                <Outlet />
                                <Cookie />
                                <Footer />
                            </>
                        </div>
                        <div className="col-1 col-sm-2 col-md-3 col-lg-4 mb-5"></div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Layout;
