import { Link } from "react-router-dom";

import SmallLogo from "./SmallLogo";

const NotFound = () => {
    return (
        <div className="NotFound firstRow row text-center items-center mt-5">
            <div className="not-found-logo-row">
                <SmallLogo />
            </div>
            <h2 className="mt-3">Etsimääsi sivua ei löytynyt</h2>
            <div className="mt-4">
                <button className="btn btn-light btn btn-outline-light">
                    <Link className="linkTxt" to="/kirjautunut">
                        <p className="linkTxt mb-1 mt-1">Palaa etusivulle</p>
                    </Link>
                </button>
            </div>
        </div>
    );
};

export default NotFound;
