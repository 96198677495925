import { genderAttributeDisplayFinToEng } from "./genderAttributeTranslator";

export function validatePhoneNumber(phoneNumber: string): boolean {
    const regex = /^\+358\d{9}$/;
    return regex.test(phoneNumber);
}

const removeSpacesAndHyphens = (str: string): string => {
    return str.replace(/[\s-]/g, "");
};

const isAtLeastTwoChars = (str: string): boolean => {
    return str.length >= 2;
};

export const nameValidator = (name: string): boolean => {
    let newName: string = removeSpacesAndHyphens(name);
    if (isAtLeastTwoChars(newName)) {
        return /^[a-zA-Z]+$/.test(newName);
    }
    return false;
};

export const genderValidator = (finGendre: string): boolean => {
    const engGender: string = genderAttributeDisplayFinToEng(finGendre);
    if (engGender === "notFound") return false;
    return true;
};
