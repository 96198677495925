import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import { useUserLoggedIn } from "../contexts/UserLoggedInContex";

import {
    CookieResponse,
    getCookie,
    deleteCookie,
    cookieExists,
} from "../utils/cookieHandler";

function SignoutAndDeleteConfirm() {
    const { userLoggedInDispatch } = useUserLoggedIn();
    const [deletedUserName, setDeletedUserName] = useState<string>("");

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const clearState = () => {
        deleteCookie("userDeleted");
        userLoggedInDispatch({
            type: "loggedIn",
            payload: "false",
        });
        userLoggedInDispatch({
            type: "setUserDeleted",
            payload: false,
        });
    };

    useEffect(() => {
        userLoggedInDispatch({
            type: "emptyUser",
            payload: null,
        });
        deleteCookie("email");
        if (cookieExists("userDeleted")) {
            const userEmail: CookieResponse = getCookie("userDeleted");
            setDeletedUserName(userEmail.value as string);
        } else {
            navigate(from, { replace: true });
        }
    }, []); //eslint-disable-line

    const userDeletionNotice = async () => {
        clearState();
    };
    return (
        <div className="delete-confirm row text-center border-5">
            <p>Käyttäjä sähköpostilla </p>
            <p>{deletedUserName}</p>
            <p>on poistettu.</p>
            <div className="mt-5">
                <button
                    className="btn btn-light btn btn-outline-light"
                    onClick={userDeletionNotice}
                >
                    <Link className="linkTxt" to="/">
                        <p className="linkTxt mb-1 mt-1">Palaa etusivulle</p>
                    </Link>
                </button>
            </div>
        </div>
    );
}

export default SignoutAndDeleteConfirm;
