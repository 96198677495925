import { useState, useEffect } from "react";

import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import type { DialogProps } from "@mui/material";

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40vh",
    bgcolor: "background.paper",
    border: "2px solid #000",
    radius: "8px",
    // border-radius: "8px",
    boxShadow: 24,
    // p: 4,
};

const Cookie = () => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const handleOpen = () => setShowModal(true);
    const clickedOnConsent = () => setShowModal(false);

    const handleClose: DialogProps["onClose"] = (event, reason) => {
        if (reason && reason === "backdropClick") {
            return;
        }

        clickedOnConsent();
    };

    useEffect(() => {
        if (!getCookieConsentValue()) {
            handleOpen();
        } else {
            setShowModal(false);
        }
    }, []);

    return (
        <>
            <div>
                <Modal open={showModal} onClose={handleClose}>
                    <Box sx={style}>
                        <CookieConsent
                            debug={true}
                            sameSite="strict"
                            location="bottom"
                            style={{
                                background: "#000",
                                justifyContent: "center",
                                // margin: "2vh",
                            }}
                            buttonStyle={{
                                marginTop: "0px",
                                borderRadius: "8px",
                                color: "#000",
                                background: "#fff",
                            }}
                            contentStyle={{
                                flex: "0 0 100%",
                                textAlign: "center",
                            }}
                            buttonText="Hyväksyn"
                            expires={30}
                            onAccept={clickedOnConsent}
                        >
                            <p className="m-1">
                                Sivustolla on käytössä välttämättömät evästeet.{" "}
                                <br />
                                Jatkaaksesi sivuston käyttöä sinun tulee
                                hyväksyä ne. <br />
                                <br />
                                <a
                                    className="under-line-link-text"
                                    href="https://liiga.fi/fi/liiga/evastekaytannot"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Voit tutustua evästekäytäntöihimme täältä.
                                </a>
                            </p>
                        </CookieConsent>
                    </Box>
                </Modal>
            </div>
        </>
    );
};

export default Cookie;
