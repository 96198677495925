import React from "react";
import ReactDOM from "react-dom/client";

import { Amplify } from "aws-amplify";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { fiFI } from "@mui/material/locale";

import "./index.css";
// // Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// // Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import App from "./App";
import { UserLoggedInProvider } from "./contexts/UserLoggedInContex";

//modified
//Amplify variables
const userPoolId: string = "eu-north-1_vBNfZ0ZKR"
const userPoolClientId: string = "1fm4oe8sk9tmgi3ugqab22qsf0"
const domain: string = "tps-mobile-staging.auth.eu-north-1.amazoncognito.com";
const redirectSignIn: string = "https://tpsstaging.liiga.fi"
const redirectSignOut: string = "https://tpsstaging.liiga.fi"

/*
AMPLIFY CONFIG
*/
Amplify.configure({
    Auth: {
        Cognito: {
            userPoolId: userPoolId,
            userPoolClientId: userPoolClientId,
            signUpVerificationMethod: "code", // 'code' | 'link'
            loginWith: {
                oauth: {
                    domain: domain,
                    scopes: [
                        "aws.cognito.signin.user.admin",
                        "email",
                        "openid",
                        "profile",
                    ],
                    redirectSignIn: [redirectSignIn],
                    redirectSignOut: [redirectSignOut],
                    responseType: "code",
                },
            },
        },
    },
});
//==========================


const theme = createTheme(
    {
        // palette: {
        //     primary: {
        //         main: "#1976d2",
        //     },
        // },
    },
    fiFI
);

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
                <UserLoggedInProvider>
                    <ThemeProvider theme={theme}>
                        <Routes>
                            <Route path="/*" element={<App />} />
                        </Routes>
                    </ThemeProvider>
                </UserLoggedInProvider>
        </BrowserRouter>
    </React.StrictMode>
);
