import { useState, useEffect } from "react";

import { useUserLoggedIn } from "../contexts/UserLoggedInContex";

import { handleUpdateUserAttribute } from "../utils/userAttributesUpdater";
import { validatePhoneNumber } from "../utils/userAttributeValidator";

import {
    FormGroup,
    TextField,
    Box,
    FormControlLabel,
    Checkbox,
} from "@mui/material";

interface PageState {
    phoneNumber: String;
    marketing: boolean;
    termsAndConditions: boolean;
}
const initialState: PageState = {
    phoneNumber: "+358",
    marketing: false,
    termsAndConditions: false,
};

interface PageError {
    errMsg: String;
    errMsgPhone: String;
    errMsgtermsAndConditions: String;
}

const initialErrorMsg: PageError = {
    errMsg: "",
    errMsgPhone: "",
    errMsgtermsAndConditions: "",
};

const UpdatePhoneNumber = () => {

    const { userLoggedInState, userLoggedInDispatch } = useUserLoggedIn();

    const [inputs, setInputs] = useState<PageState>(initialState);
    const [error, setError] = useState<PageError>(initialErrorMsg);

    //function that handles the calls for updateing phone number and marketing permission
    const updateUserAttributes = async () => {
        try {

            const updatedPhoneNumber: Promise<string> = //eslint-disable-line
                handleUpdateUserAttribute(
                    "phone_number",
                    inputs.phoneNumber as string
                );

            const updatedGivenName: Promise<string> = handleUpdateUserAttribute( //eslint-disable-line
                "custom:market_perm",
                inputs.marketing ? "1" : "0"
                // marketingValueToStr
            );

            userLoggedInDispatch({
                type: "setLoggedInUserAttributes",
                payload: {
                    ...userLoggedInState.userAttributes,
                    phone_number: inputs.phoneNumber as string,
                    "custom:market_perm": inputs.marketing ? "1" : "0",
                },
            });

        } catch (error) {
            handleErrorMessage("errMsg", error as string);
        }
    };

    /**
     * Hanlder functions
     */
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setInputs((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.checked,
        }));
    };

    const handlePhoneNumberChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ): void => {
        setInputs((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    const handleErrorMessage = (targetError: string, message: string): void => {
        setError((prevState) => ({
            ...prevState,
            [targetError]: message,
        }));
    };

    // function that handles form submitting and possible errors
    const onSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const number: string = inputs.phoneNumber.split(" ").join("");
        if (!validatePhoneNumber(number)) {
            handleErrorMessage(
                "errMsgPhone",
                "Puhelin numeron tulee olla muodossa +358 xx xxxxxxx"
            );
        }
        if (!inputs.termsAndConditions) {
            handleErrorMessage(
                "errMsgtermsAndConditions",
                "Sinun täytyy hyväksyä käyttöehdot ja tietosuojakäytänne."
            );
        }
        if (validatePhoneNumber(number) && inputs.termsAndConditions) {
            setInputs((prevState) => ({
                ...prevState,
                phoneNumber: number,
            }));
            updateUserAttributes();
        }
    };

    useEffect(() => {
        handleErrorMessage("errMsg", "");
    }, [inputs.phoneNumber, inputs.phoneNumber, inputs.termsAndConditions]);
    useEffect(() => {
        handleErrorMessage("errMsgPhone", "");
    }, [inputs.phoneNumber]);
    useEffect(() => {
        handleErrorMessage("errMsgtermsAndConditions", "");
    }, [inputs.termsAndConditions]);

    return (
        <section>
            <div className="row mt-5 pt-5 mb-3 ms-1 text-center">
                <h2>PÄIVITÄ PUHELINNUMERO</h2>
            </div>
            <div
                // ref={errRef}
                className={
                    error.errMsg ||
                    error.errMsgPhone ||
                    error.errMsgtermsAndConditions
                        ? "errmsg text-center"
                        : "offscreen"
                }
                aria-live="assertive"
            >
                <p className="mb-1">{error.errMsg}</p>
                <p className="mb-1">{error.errMsgPhone}</p>
                <p className="mb-4">{error.errMsgtermsAndConditions}</p>
            </div>
            <form onSubmit={onSubmitForm}>
                <FormGroup>
                    <TextField
                        className="pb-2"
                        label={"Puhelinnumero"}
                        name="phoneNumber"
                        value={inputs.phoneNumber}
                        onChange={handlePhoneNumberChange}
                        type={"text"}
                        placeholder={"Puhelinnumero"}
                        sx={{
                            ".MuiInputLabel-root": {
                                color: `white !important`,
                            },
                        }}
                    />
                    <div className="row ms-3 mt-5">
                        <Box>
                            <Box>
                                <FormControlLabel
                                    label="Haluan vastaanottaa markkinointi-ilmoituksia"
                                    control={
                                        <Checkbox
                                            name="marketing"
                                            checked={inputs.marketing}
                                            onChange={handleChange}
                                        />
                                    }
                                ></FormControlLabel>
                            </Box>
                            <Box>
                                <FormControlLabel
                                    label={
                                        <>
                                            Olen lukenut ja hyväksynyt{" "}
                                            <a
                                                className="confirmation-page-link-text"
                                                href="käyttöehdot"
                                            >
                                                käyttöehdot
                                            </a>{" "}
                                            ja{" "}
                                            <a href="https://liiga.fi/fi/liiga/tietosuojaseloste">
                                                tietosuojakäytännön
                                            </a>
                                        </>
                                    }
                                    control={
                                        <Checkbox
                                            name="termsAndConditions"
                                            checked={inputs.termsAndConditions}
                                            onChange={handleChange}
                                        />
                                    }
                                ></FormControlLabel>
                            </Box>
                        </Box>
                    </div>
                    <div className="row mt-5">
                        <div className="text-center">
                            {/* <button className="btn btn-outline-secondary"> */}
                            <button className="btn btn-light">Jatka</button>
                        </div>
                    </div>
                </FormGroup>
            </form>
        </section>
    );
};

export default UpdatePhoneNumber;
