import { useLocation, Navigate, Outlet } from "react-router-dom";

import { useUserLoggedIn } from "../contexts/UserLoggedInContex";

const RequireAuth = () => {
    const { userLoggedInState } = useUserLoggedIn();
    const location = useLocation();

    if (userLoggedInState?.LoggedInStatus === "true") {
        return <Outlet />;
    } else {
        return userLoggedInState.userDeleted === true ? (
            <>
                <Navigate to="/ulos" state={{ from: location }} replace />
            </>
        ) : (
            <>
                <Navigate to="/" state={{ from: location }} replace />
            </>
        );
    }
};

export default RequireAuth;
