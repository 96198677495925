import { useState, useEffect } from "react";

import { Google, Apple, Facebook } from "react-bootstrap-icons";
import { Button } from "react-bootstrap";

import { useNavigate, useLocation } from "react-router-dom";

import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

import SmallLogo from "./SmallLogo";

import { signIn, type SignInInput, signInWithRedirect } from "aws-amplify/auth";


import { useUserLoggedIn } from "../contexts/UserLoggedInContex";

import { errorMessageHandler } from "../utils/errorMessageHandler";

function Login() {
    const [visible, setVisible] = useState<boolean>(false);

    const [errMsg, setErrMsg] = useState<string>("");
    const [userEmail, setUserEmail] = useState<string>("");
    const [pwd, setPwd] = useState<string>("");


    const { userLoggedInDispatch } = useUserLoggedIn();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/kirjautunut";

    async function handleSignIn({ username, password }: SignInInput) {
        try {
            const { isSignedIn }: boolean | any = await signIn({
                username,
                password,
            });
            setUserEmail("");
            setPwd("");
            if (isSignedIn === true) {
                userLoggedInDispatch({
                    type: "loggedIn",
                    payload: "true",
                });
            }
            navigate(from, { replace: true });
        } catch (error) {
            const errorMessage: string = errorMessageHandler(error);
            if (
                errorMessage === "username is required to signIn" ||
                errorMessage === "password is required to signIn"
            ) {
                setErrMsg(
                    "sisäänkirjautumiseen vaaditaan sähköposti ja salasana"
                );
            } else if (errorMessage === "Incorrect username or password.") {
                setErrMsg("Väärä käyttäjätunnus tai salasana");
            } else {
                setErrMsg("Jokin meni pieleen");
            }

        }
    }



    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const username: string = userEmail;
        const password: string = pwd;
        handleSignIn({ username, password });
    };


        useEffect(() => {
        setErrMsg("");
    }, [userEmail, pwd]);

    return (
        <>
            <div className="login row d-flex justify-content-center align-items-center ms-1 me-1 border-0">
                <div className="login title row mb-2 mt-3 text-center">
                    <h2>KIRJAUDU SISÄÄN</h2>
                </div>
                <div className="row mt-3">
                    <SmallLogo />
                    <h3 className="text-center mt-2">Tervetuloa</h3>
                    {/* <p className="login-info-text text-center">
                        Jotta voit poistaa tunnuksesi tulee sinun kirjautua
                        sisään sovellukseen
                    </p> */}
                </div>
                <section>
                    <div className="sign-in">
                        <p
                            className={
                                errMsg ? "errmsg text-center" : "offscreen"
                            }
                            aria-live="assertive"
                        >
                            {errMsg}
                        </p>

                        <form onSubmit={handleSubmit}>
                            <div className="email row mb-3">
                                <label htmlFor="email"></label>
                                <input
                                    className="p-1"
                                    type="text"
                                    onChange={(e) =>
                                        setUserEmail(e.target.value)
                                    }
                                    placeholder="sähköposti"
                                    // value={userEmail}
                                />
                            </div>
                            <div className="password row">
                                <label htmlFor="password"></label>
                                <div className="d-flex p-0 m-0">
                                    <div className="flex-grow-1">
                                        <input
                                            className="salasana p-1"
                                            type={visible ? "text" : "password"}
                                            onChange={(e) =>
                                                setPwd(e.target.value)
                                            }
                                            // value={pwd}
                                            placeholder="salasana"
                                        />
                                    </div>
                                    <div className="position-relative">
                                        <div
                                            className="position-absolute top-0 end-0 me-3"
                                            onClick={() => setVisible(!visible)}
                                        >
                                            {visible ? (
                                                <EyeInvisibleOutlined />
                                            ) : (
                                                <EyeOutlined />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sign-in row mb-2 mt-4">
                                <button className="btn btn-light">
                                    Kirjaudu
                                </button>
                            </div>
                        </form>
                    </div>
                </section>

                <div className="row">
                    <div className="col-5">
                        <hr />
                    </div>
                    <div className="col-2 text-center">
                        <p>tai</p>
                    </div>
                    <div className="col-5">
                        <hr />
                    </div>
                </div>

                <section>
                    <div className="social-login row text-center">
                        <div className="col-4">
                            <Button
                                variant="light"
                                onClick={() => {
                                    signInWithRedirect({
                                        provider: "Facebook",
                                    });
                                }}
                            >
                                <Facebook size={24} />
                            </Button>
                        </div>
                        <div className="col-4">
                            <Button
                                variant="light"
                                onClick={() => {
                                    signInWithRedirect({
                                        provider: "Google",
                                    });
                                }}
                            >
                                <Google size={24} />
                            </Button>
                        </div>
                        <div className="col-4">
                            <Button
                                variant="light"
                                onClick={() => {
                                    signInWithRedirect({
                                        provider: "Apple",
                                    });
                                }}
                            >
                                <Apple size={24} />
                            </Button>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default Login;
