import { signOut } from "aws-amplify/auth";

import { useUserLoggedIn } from "../contexts/UserLoggedInContex";

import { deleteCookie } from "../utils/cookieHandler";

function Logout() {
    const { userLoggedInState, userLoggedInDispatch } = useUserLoggedIn();

    async function handleSignOut() {
        try {
            deleteCookie("email");
            await signOut({ global: true });

            //this has to be done so that the user how are using cognito pool credentials will logout 
            if (!userLoggedInState.userAttributes?.identities) {
                userLoggedInDispatch({
                    type: "loggedIn",
                    payload: "false",
                });
            }
        } catch (error) {
            userLoggedInDispatch({ type: "loggedIn", payload: "true" });
        }
    }

    return (
        <>
            {userLoggedInState.LoggedInStatus === "true" ? (
                <div className="logout container position-relative">
                    <p
                        role="button"
                        className="text-light position-absolute top-0 end-0 mt-3"
                        onClick={handleSignOut}
                    >
                        KIRJAUDU ULOS
                    </p>
                </div>
            ) : null}
        </>
    );
}

export default Logout;
