import { useEffect, Suspense } from "react";

import { fetchUserAttributes } from "aws-amplify/auth";

import { Routes, Route } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";

import "./App.css";

import {
    useUserLoggedIn,
    LoggedInUserAttributes,
} from "./contexts/UserLoggedInContex";
import { cookieExists } from "./utils/cookieHandler";

//components
import Layout from "./components/Layout";
import Loader from "./components/Loader";
import Login from "./components/Login";
import LoggedIn from "./components/LoggedIn";
import SignoutAndDeleteConfirm from "./components/SignoutAndDeleteConfirm";
import RequireAuth from "./components/RequireAuth";
import EditUserAttributes from "./components/EditUserAttributes";
import NotFound from "./components/NotFound";

function App() {

    const { userLoggedInDispatch } = useUserLoggedIn();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/kirjautunut";

    async function handleFetchUserAttributes() {
        try {
            const userAttributes: LoggedInUserAttributes =
                await fetchUserAttributes();
            if (userAttributes) {
                userLoggedInDispatch({ type: "loggedIn", payload: "true" });
                userLoggedInDispatch({
                    type: "setLoggedInUserAttributes",
                    payload: userAttributes,
                });
                navigate(from, { replace: true });
            }
        } catch (error) {
            userLoggedInDispatch({ type: "loggedIn", payload: "false" });
        }
    }

    useEffect(() => {
        // if there is this cookie --> redirects to /ulos
        if (cookieExists("userDeleted")) {
            userLoggedInDispatch({
                type: "setUserDeleted",
                payload: true,
            });
            navigate(from, { replace: true });
        }
        handleFetchUserAttributes();
    }, []); //eslint-disable-line

    return (
        <Suspense fallback={<Loader />}>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route path="" element={<Login />} />
                    <Route element={<RequireAuth />}>
                        <Route path="/kirjautunut" element={<LoggedIn />} />
                        <Route
                            path="/muokkaa"
                            element={<EditUserAttributes />}
                        />
                    </Route>
                    <Route path="/ulos" element={<SignoutAndDeleteConfirm />} />
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        </Suspense>
    );
}

export default App;
