import { getAssetsUrl } from "../utils/getAssets";

import logo from "../assets/logo/logo.png";
const brand: string = "TPS"

//use this if there is a place to retrieve asset
let logoAddress: string = getAssetsUrl("logo", brand) as string;
if (logoAddress === "") logoAddress = logo;

function SmallLogo() {
    return (
        <div className="small-logo row ms-1">
            <div className="">
                <img className="logo" src={logoAddress} alt="Logo" />
            </div>
        </div>
    );
}

export default SmallLogo;
